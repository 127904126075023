.error-display-text {
    margin: 2rem 1rem;
}

.error-code-text {
    font-size: 8.5rem;
    font-weight: normal;
    margin: 3.75rem 0rem;
    color: var(--r-catapult-orange);
}
