.back-button {
    position: absolute;
    left: 16px;
    top: 16px;
}

.close-button {
    position: absolute;
    right: 16px;
    top: 16px;
}
