.oauth-dialog__container {
    max-height: 800px;
    position: relative;
    width: 500px;
    padding: 45px;
}
.oauth-dialog__close-button {
    position: absolute;
    top: 15px;
    right: 15px;
}
