html,
body {
    margin: 0;
    min-height: 100vh;
    padding: 0;
}

body a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

/**
 These rules ensure SVGs included from the design system are rendered at the correct size.
 The design system currently renders svg with width and height set to 1em instead of the
 actual intended size of 16px or 32px, but changing that would be a breaking change for
 other DS consumers.
*/
svg[viewBox='0 0 16 16'] {
    height: 16px;
    width: 16px;
}

svg[viewBox='0 0 17 16'] {
    height: 16px;
    width: 17px;
}

/** rsuite overrides **/
body .rs-table-cell-header .rs-table-cell-content {
    align-items: center;
    background-color: var(--r-grey-6);
    border-bottom: 1px solid var(--r-grey-4);
    border-top: 1px solid var(--r-grey-4);
    color: var(--r-catapult-black);
    display: flex;
    font-size: 14px;
    font-weight: 600;
}

body .rs-table-cell-content {
    font-size: 13px;
    letter-spacing: -0.3px;
    padding: 13px 8px;
}

body .rs-table-cell-header-icon-sort {
    color: black;
}

body .rs-table-cell-header-icon-sort-desc::after {
    color: black;
}

body .rs-table-cell-header-icon-sort-asc::after {
    color: black;
}

body .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn,
body .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-right: 3.75em;
}

body .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
body .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    right: 34px;
    top: 10px;
}

body .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
body .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
body .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    color: var(--r-grey-5);
    font-size: 10px;
}

body .rs-btn-toggle {
    background-color: #4e555b;
    font-size: 13px;
}

body .rs-btn-toggle-checked,
body .rs-btn-toggle-checked:hover {
    background-color: #0f9119;
    font-size: 13px;
}

body .rs-btn-sm {
    font-size: 12px;
}

body .rs-control-label {
    display: inline-block;
    margin-bottom: 4px;
}

.confirmation-modal .rs-modal-content {
    max-height: 90px;
    padding-left: 10px;
}
body .rs-modal-xs .rs-modal-body {
    margin-top: 0;
}

/* To be visible in front of material ui modal */
body .rs-picker-menu,
body .rs-modal-wrapper,
body .rs-modal-content,
body .rs-tooltip,
body .rs-modal-open .rs-picker-menu {
    z-index: 2000;
}

/* copied from bootstrap */
.badge {
    align-items: center;
    border-radius: 0.25rem;
    display: flex;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    white-space: nowrap;
}

.rs-btn-icon .badge {
    display: inline-block;
}

.badge .rs-loader,
.badge .rs-loader .rs-loader-spin,
.badge .rs-loader .rs-loader-spin::before,
.badge .rs-loader .rs-loader-spin::after {
    height: 1em;
    width: 1em;
}

.badge .rs-loader-spin::before,
.badge .rs-loader-spin::after {
    border-width: 2px;
}

.badge-dark {
    color: var(--r-catapult-white);
    background-color: var(--r-grey-1);
}

.badge-success {
    color: var(--r-catapult-white);
    background-color: var(--main-ltsfc-success-green);
}

.badge-error {
    color: var(--r-catapult-white);
    background-color: var(--main-ltsfc-alert-red);
}

.badge-warning {
    color: var(--r-catapult-white);
    background-color: var(--main-ltsfc-warning-yellow-text);
}

.badge-info {
    color: var(--r-catapult-white);
    background-color: var(--main-ltsfc-info-blue);
}

/* Material UI overrides */
.MuiDialog-paperWidthSm {
    width: 600px;
}

/* Move modal backdrop down a little so ajax loader is visible :-D */
.MuiDialog-root .MuiBackdrop-root {
    top: 4px;
}

.MuiDialog-root .rs-table-affix-header.fixed {
    margin-top: 50px;
}

.MuiLink-root.MuiTypography-colorTextSecondary {
    color: var(--r-catapult-black);
}

/** MuiSwitch (toggle) */
body .MuiSwitch-root {
    height: 32px;
    margin-right: 8px;
    padding: 2px;
    width: 60px;
}

body .MuiSwitch-root .MuiIconButton-root:hover {
    background-color: transparent;
}

body .MuiSwitch-track {
    border-radius: 14px;
}
body .MuiSwitch-thumb {
    box-shadow: none;
    height: 24px;
    width: 24px;
}
body .MuiSwitch-switchBase {
    padding: 4px;
}
body .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(28px);
}

body .MuiSwitch-sizeSmall.MuiSwitch-root {
    height: 24px;
    width: 44px;
    padding: 2px;
}

body .MuiSwitch-sizeSmall .MuiSwitch-thumb {
    height: 16px;
    width: 16px;
}
body .MuiSwitch-sizeSmall .MuiSwitch-track {
    border-radius: 14px;
}
body .MuiSwitch-sizeSmall .MuiSwitch-switchBase {
    padding: 4px;
}
body .MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(20px);
}

/** For react-markdown */
.strip-p p {
    display: inline;
}

/** Autocomplete */
body .MuiAutocomplete-clearIndicator {
    margin-right: 4px;
    color: var(--r-grey-3);
    font-size: 16px;
}
body .MuiAutocomplete-popupIndicator {
    color: var(--r-catapult-black);
    font-size: 16px;
}
body .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment {
    right: 19px;
}

body .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline {
    border-color: var(--r-catapult-black);
}

/** Select */
body .MuiSelect-select {
    color: var(--r-catapult-black);
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

body .MuiSelect-select:focus {
    background-color: transparent;
}

.MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px 16px;
    padding-right: 36px;
}

.MuiOutlinedInput-root .MuiSelect-iconOutlined {
    color: var(--r-catapult-black);
    font-size: 16px;
    right: 12px;
    top: calc(50% - 8px);
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--r-grey-3);
    transition-duration: 100ms;
    transition-property: border-width;
}

body .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--r-catapult-black);
    border-width: 1px;
}

body .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
}

.MuiInput-ext-sizeSmall .MuiSelect-outlined.MuiSelect-outlined {
    padding: 4px 12px;
    padding-right: 36px;
}

.MuiInput-ext-sizeReferee .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px 12px;
    padding-right: 36px;
}

/* allows referee date picker popup to display in front of MUI Dialog (e.g. activity admin date range picker) */
referee-portal > div {
    z-index: 1500 !important;
}

/* Override referee-react/dist/reset.css */
.rs-picker-value-count,
.rs-flex-box-grid-item {
    min-width: auto;
}

/* Override ag-grid settings*/
div .ag-header-cell {
    height: unset;
}

.ag-floating-bottom {
    border-top: 2px solid var(--r-grey-5) !important;
    border-bottom: 1px solid var(--r-grey-5) !important;
}

@media (max-width: 769px) {
    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
        padding-left: 0 !important;
    }
}

.rmsc .select-panel * {
    --rmsc-bg: #fafafa!important;
}

.rmsc .dropdown-content {
    z-index: 100 !important;
    border: 0;
}

.rmsc .search {
    padding: 12px 16px;
    border: none;
}

.rmsc .search input {
    background: var(--r-catapult-white)!important;
    border: 1px solid #666!important;
    border-radius: 8px;
}

.rmsc .search input:hover {
    border: 2px solid black!important;
}

.rmsc .search input:focus {
    border: 2px solid #4285f4!important;
}

.rmsc .dropdown-content .panel-content {
    box-shadow: 0 7px 8px -4px hsla(0, 0%, 6%, .2), 0 12px 17px 2px hsla(0, 0%, 6%, .14), 0 5px 22px 4px hsla(0, 0%, 6%, .12) !important;
    border-radius: 8px !important;
}
.rmsc .select-item:hover {
    background: var(--r-selected-overlay)!important;
}
.rmsc .select-item.selected{
    background: var(--r-catapult-white)!important;
}
.rmsc .select-item.selected:hover{
    background: var(--r-selected-overlay)!important;
}

.select-item:has(.item-renderer.disabled),
.select-item:has(.item-renderer.disabled) * {
    cursor: default;
    pointer-events: none;
}
