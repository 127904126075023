:root {
    --main-header-height: 64px;
}

.layout-header {
    align-items: stretch;
    background: var(--r-catapult-black);
    height: var(--main-header-height);
    position: relative;
    z-index: 1300;
}

.layout-header,
.layout-header a,
.layout-header a:hover,
.layout-header a:active {
    color: var(--r-catapult-white);
}

.nav-bar {
    display: grid;
    grid-template-columns: max-content max-content auto;
    background-color: var(--r-catapult-black);
    position: fixed;
    height: inherit;
    width: 100%;
    z-index: 3;
    grid-auto-flow: dense;
}

.logo-link {
    align-self: center;
    display: block;
    font-size: 2em;
    text-decoration: none;
}

.logo-link .logo-image {
    height: 36px;
    width: 69px;
}

.menu {
    clear: none;
    min-width: 110%;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: clip;
    background-color: var(--r-catapult-black);
    display: flex;
}

.menu-item {
    align-items: stretch;
    border: solid transparent;
    border-width: 4px 0;
    display: flex;
    font-family: 'Mark Pro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    position: relative;
}

.menu-item.active {
    border-bottom-color: var(--r-catapult-orange);
    font-weight: bold;
}

.menu-item a,
.menu-item a:hover,
.menu-item a:active,
.menu-item a:focus {
    align-items: center;
    color: inherit;
    cursor: pointer;
    display: flex;
    margin: -4px 0;
    outline: 0;
    padding: 0 18px;
    text-decoration: none;
}

.menu-item .label,
.menu-item-report-select input {
    border-radius: 4px;
    padding: 8px;
}

.menu-item a:hover .label,
.menu-item-report-select:hover input {
    background: var(--r-selected-overlay);
}

.menu-item a:active .label,
.menu-item a:focus .label {
    background: var(--r-focus-overlay);
}

.menu-item-report-select {
    align-items: center;
    width: 200px;
}

.menu-item-report-select input {
    height: 36px;
}

.menu-item-report-select > div > div > div > div > div > div > div > div > div {
    background: none;
    box-shadow: none;
}

.menu-item-report-select a,
.menu-item-report-select a:hover,
.menu-item-report-select a:active,
.menu-item-report-select a:focus {
    height: 40px;
}

.shared-nav-bar {
    display: flex;
    background-color: var(--r-catapult-black);
    position: fixed;
    height: inherit;
    width: 100%;
    z-index: 3;
    justify-content: space-between;
}

.shared-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;
}

.shared-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    margin-right: 16px;
}

/* menu btn */

.menu-btn {
    display: none;
}

.menu-btn:checked ~ .menu {
    max-height: 240px;
}

.menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
}

.menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .nav-icon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .nav-icon:after {
    top: 0;
}

/* menu icon */

.menu-icon {
    display: none;
}

.menu-icon .nav-icon {
    background: var(--r-catapult-white);
    display: block;
    height: 2px;
    position: relative;
    width: 18px;
}

.menu-icon .nav-icon:before,
.menu-icon .nav-icon:after {
    background: var(--r-catapult-white);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
}

.menu-icon .nav-icon:before {
    top: 5px;
}

.menu-icon .nav-icon:after {
    top: -5px;
}

.account {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0 16px;
}

.loader {
    position: absolute;
    top: 0;
    width: 100vw;
}

div .unread-badge {
    background: var(--main-ltsfc-alert-red);
    border-radius: 8px;
    color: var(--r-catapult-white);
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    margin-bottom: 16px;
    margin-left: -8px;
    min-width: 16px;
    padding: 0 4px;
    text-align: center;
    transform: scale(0, 0);
    transition-duration: 100ms;
    z-index: 9999;
}

.unread .unread-badge {
    transform: scale(1, 1);
}

.help-icon,
.notifications-icon {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 0 0 0 2px;
}

.notifications-icon .unread-badge {
    margin-left: -16px;
}

.info-items {
    display: flex;
    justify-self: end;
}

/* Smaller screens */

@media (max-width: 1023px) {
    .nav-bar {
        align-content: center;
        height: auto;
        grid-template-columns: min-content min-content auto;
    }

    .menu {
        clear: both;
        display: flex;
        flex-direction: column;
        grid-column: span 3;
        max-height: 0;
        overflow: hidden;
    }

    .logo-link {
        order: 2;
        width: 50px;
    }

    .logo-link .logo-image {
        height: 36px;
        width: auto;
    }

    .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 8px 28px 20px;
        position: relative;
        user-select: none;
        order: 1;
        width: 0;
    }

    .info-items {
        order: 3;
    }

    .menu {
        order: 4;
    }

    .menu .menu-item {
        display: grid;
        min-width: 100%;
        border-width: 0 4px;
        margin: 0;
        height: 40px;
    }

    .menu .menu-item-report-select {
        min-width: 100%;
        border-width: 0 4px;
        margin: 0;
        border-bottom: 1px solid var(--r-grey-2);
        height: 40px;
    }

    .menu .menu-item-report-select.active {
        border-left-color: var(--r-catapult-orange);
    }

    .menu .menu-item.active {
        border-left-color: var(--r-catapult-orange);
        border-bottom: 0;
    }

    .menu .menu-item:not(:last-child) {
        border-bottom: 1px solid var(--r-grey-2);
    }
}
