.button {
    background-color: var(--r-catapult-black);
    border: solid 3px var(--r-grey-6);
    border-radius: 2px;
    color: var(--r-catapult-white);
    font-size: 26px;
    height: 48px;
    line-height: 44px;
    padding: 0;
    position: relative;
    text-align: center;
    width: 48px;
    transition-property: border-color;
    transition-duration: 100ms;
}

.logo {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.button.active {
    border-color: var(--r-catapult-orange);
}
