.legacy-iframe-container {
    height: calc(100vh - var(--main-header-height));
    overflow: hidden;
    position: relative;
}

.legacy-iframe-container.full-height {
    height: 100vh;
}

.legacy-iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
