.icon-external {
    width: 12px;
    height: 12px;
    color: var(--r-catapult-black);
}
.icon-help {
    width: 24px;
    height: 24px;
    color: var(--r-catapult-black);
}
