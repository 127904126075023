.side-bar-container {
    height: 100%;
    display: flex;
}

.side-bar-container [data-testId='side-bar-menu'] {
    margin-right: 0;
    z-index: 999;
}

.side-bar-container [data-testId='side-bar-menu'] > div {
    margin-right: 0;
}

.side-bar-container .filter-column svg {
    color: var(--r-catapult-white);
}

.activity-menu-title {
    text-align: left;
    margin: 0 1rem 1rem;
}

.activity-menu-title > label {
    font-weight: 700;
    color: var(--r-grey-5);
}

#activity-list {
    overflow-y: auto;
    color-scheme: dark;
}

.menu-activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0.5rem 0.5rem 1rem;
    margin-bottom: 0.25rem;
    height: 3rem;
}

.menu-activity-text {
    width: 70%;
}

.menu-activity-text > div:first-of-type {
    font-weight: 700;
}

.menu-activity-tag {
    width: 30%;
    justify-content: center;
    display: flex;
}

.selected {
    padding: 1rem 0.5rem 1rem 0.5rem;
    border-left: 0.5rem solid var(--r-catapult-orange);
    background-color: rgba(255, 255, 255, 0.16);
}

.centered-span {
    display: flex;
    justify-content: center;
}

.centered-span svg {
    color: var(--r-catapult-white);
}

.filter-column {
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.filter-column > div {
    margin-top: 0.5rem;
}

[data-radix-scroll-area-viewport] {
    overflow: hidden !important;
}

.activity-menu-date {
    text-transform: uppercase;
    font-style: normal;
    font-size: 12px;
}

.button-item {
    height: 48px;
    padding: 15px;
    display: inline-block;
    width: 100%;
}

.footer-item {
    height: 12px;
}

.footer-button-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 12px;
    margin-right: 12px;
}
