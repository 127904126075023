body .login-text-field-label {
    font-size: 12px;
    font-weight: bold;
    display: block;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase;
}

body .login-text-field-input {
    background-color: var(--r-catapult-white);
    color: var(--r-catapult-black);
    border: solid 1px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 16px;
}
