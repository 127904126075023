.requested_scopes_list {
    border-radius: 5px;
}

.requested_scopes_list :first-child {
    border-width: 1px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.requested_scopes_list_item {
    font-weight: bold;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: var(--catapult-black);
}

.requested_scopes_list :last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
