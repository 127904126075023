.nested-menu .nested-menu-item-icon {
    color: var(--r-catapult-black);
    min-width: 32px;
}

.nested-menu .nested-menu-item-icon img {
    width: 16px;
}

.nested-menu .nested-menu-item-title {
    font-size: 1rem;
    justify-content: center;
}

.nested-menu .nested-menu-item-selected-icon {
    margin-left: auto;
    min-width: 32px;
    justify-content: flex-end;
}

.nested-menu .nested-menu-item-selected-icon img {
    width: 16px;
}