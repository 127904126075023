.updates-dialog__container {
    height: calc(100vh - 100px);
    max-height: 800px;
    position: relative;
    width: 700px;
}
.updates-dialog__close-button {
    position: absolute;
    top: 15px;
    right: 15px;
}

.updates {
    font-size: 16px;
    text-align: justify;
    --header-height: 70px;
    --modal-vmargin: 100px;
}
.updates a,
.updates a:focus,
.updates a:hover {
    color: var(--r-catapult-orange);
}
.updates-header {
    align-items: center;
    background: var(--r-catapult-white);
    border-bottom: solid 1px #979797;
    display: flex;
    height: var(--header-height);
    margin: 0;
    padding: 0 25px;
    width: 100%;
    z-index: 1;
}

.updates-header-mark-as-read {
    background: transparent;
    color: var(--r-catapult-orange);
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    margin: 0 0 0 2em;
    vertical-align: middle;
}
.updates-header-mark-as-read:focus {
    outline: none;
}
.unread-count {
    align-items: center;
    border: solid 1px #979797;
    border-radius: 50%;
    color: var(--r-catapult-white);
    display: flex;
    font-size: 0px;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    line-height: 30px;
    margin-right: 25px;
    text-align: center;
    width: 30px;
    cursor: default;
}
.unread-count.has-unread {
    background-color: var(--r-catapult-orange);
    border: 0;
    color: var(--r-catapult-white);
    cursor: pointer;
    font-size: 20px;
}
.updates-list {
    overflow: auto;
    height: calc(100vh - var(--header-height) - var(--modal-vmargin));
    max-height: calc(800px - var(--header-height));
}
.updates-list .loading-placeholder {
    margin: 35px 60px 35px 80px;
}
.updates-list-item {
    margin: 35px 60px 35px 80px;
    padding: 0;
}
.updates-list-item-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px;
    position: relative;
}
.updates-list-item-read-status {
    border-radius: 50%;
    border: solid 1px #979797;
    cursor: pointer;
    height: 11px;
    left: -46px;
    margin-bottom: 2px;
    position: absolute;
    top: 0.5em;
    width: 11px;
}
.updates-list-item-read-status.unread {
    background-color: var(--r-catapult-orange);
    border: 0;
}
.updates-list-item-date {
    font-size: 90%;
    opacity: 0.7;
}
.updates-list-item-copy ul,
.updates-list-item-copy p {
    margin-bottom: 1em;
}
.updates-list-item-copy ul {
    padding-inline-start: 15px;
}
.updates-list-item-image {
    background-image: url('assets/updates-default.png');
    background-size: cover;
    padding-top: 57%;
}
.updates-list-item-divider {
    border-bottom: solid 1px;
    color: #979797;
    margin: 0 37.5px;
}
