.admin-table :global .rs-table-header-row-wrapper {
    z-index: auto;
}

.admin-table :global .rs-table-cell-header .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #aaa;
}
.admin-table :global .rs-checkbox-inline {
    margin-left: 0;
}
.header {
    align-items: center;
    display: flex;
    height: 55px;
    justify-content: space-between;
    padding: 5px;
}
.headerLeft,
.headerRight {
    align-items: center;
    display: flex;
    flex-grow: 1;
}
.headerLeft > *,
.headerRight > * {
    margin: 0 5px;
}

.headerRight {
    justify-content: flex-end;
}
.actions {
    display: flex;
    height: 36px;
    justify-content: flex-end;
    margin: 9px;
}
.admin-table :global .button-cell .rs-table-cell-content {
    display: flex;
    align-items: center;
}
.admin-table :global .rs-dropdown-item-submenu--placement-bottom > .rs-dropdown-item-content > .rs-dropdown-menu {
    bottom: 0;
    top: auto;
}
.page-load-indicator {
    height: 4px;
    overflow: hidden;
    z-index: 1;
    visibility: hidden;
}
.page-load-indicator.page-load-indicator--loading {
    visibility: visible;
}
.page-load-indicator .MuiLinearProgress-colorPrimary {
    background-color: #ccc;
}
.page-load-indicator .MuiLinearProgress-barColorPrimary {
    background-color: #888;
}

.admin-table .check-cell :global .rs-table-cell-content {
    padding: 0;
}

.boolean-cell {
    text-align: center;
}
.boolean-cell.boolean-cell-true {
    color: var(--main-ltsfc-success-green);
}
.boolean-cell.boolean-cell-false {
    color: var(--main-ltsfc-alert-red);
}
