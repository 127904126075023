@import 'rsuite-theme.less';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap');

@catapult-black: #101010;
@catapult-orange: #ff5500;
@catapult-white: #ffffff;
@grey-1: #292f33;
@grey-2: #404040;
@grey-3: #666666;
@grey-4: #808080;
@grey-5: #bfbfbf;
@grey-6: #e6e6e6;
@grey-7: #fafafa;
@main-ltsfc-alert-red: #e91616;
@main-ltsfc-alert-red-rgb: 233, 22, 22;
@main-ltsfc-alert-red-bg: #fef6f6;
@main-ltsfc-alert-red-text: #a71010;
@main-dksfc-alert-red: #ff6161;
@main-dksfc-alert-red-bg: #130202;
@main-dksfc-alert-red-text: #ff9494;
@main-ltsfc-warning-yellow: #e39907;
@main-ltsfc-warning-yellow-rgb: 227, 153, 7;
@main-ltsfc-warning-yellow-bg: #fffbf5;
@main-ltsfc-warning-yellow-text: #724d03;
@main-dksfc-warning-yellow: #ffc758;
@main-dksfc-warning-yellow-bg: #140e00;
@main-dksfc-warning-yellow-text: #ffd88a;
@main-ltsfc-success-green: #3a825c;
@main-ltsfc-success-green-rgb: 58, 130, 92;
@main-ltsfc-success-green-bg: #f8fcfa;
@main-ltsfc-success-green-text: #2a5f43;
@main-dksfc-success-green: #5eb886;
@main-dksfc-success-green-bg: #060e0a;
@main-dksfc-success-green-text: #95d0af;
@main-ltsfc-info-blue: #0074e0;
@main-ltsfc-info-blue-bg: #f5faff;
@main-ltsfc-info-blue-text: #003f7a;
@main-dksfc-info-blue: #3ba1ff;
@main-dksfc-info-blue-bg: #3ba1ff;
@main-dksfc-info-blue-text: #a3d3ff;

:root {
    --main-ltsfc-alert-red: @main-ltsfc-alert-red;
    --main-ltsfc-alert-red-rgb: @main-ltsfc-alert-red-rgb;
    --main-ltsfc-alert-red-bg: @main-ltsfc-alert-red-bg;
    --main-ltsfc-alert-red-text: @main-ltsfc-alert-red-text;
    --main-dksfc-alert-red: @main-dksfc-alert-red;
    --main-dksfc-alert-red-bg: @main-dksfc-alert-red-bg;
    --main-dksfc-alert-red-text: @main-dksfc-alert-red-text;

    --main-ltsfc-warning-yellow: @main-ltsfc-warning-yellow;
    --main-ltsfc-warning-yellow-rgb: @main-ltsfc-warning-yellow-rgb;
    --main-ltsfc-warning-yellow-bg: @main-ltsfc-warning-yellow-bg;
    --main-ltsfc-warning-yellow-text: @main-ltsfc-warning-yellow-text;
    --main-dksfc-warning-yellow: @main-dksfc-warning-yellow;
    --main-dksfc-warning-yellow-bg: @main-dksfc-warning-yellow-bg;
    --main-dksfc-warning-yellow-text: @main-dksfc-warning-yellow-text;

    --main-ltsfc-success-green: @main-ltsfc-success-green;
    --main-ltsfc-success-green-rgb: @main-ltsfc-success-green-rgb;
    --main-ltsfc-success-green-bg: @main-ltsfc-success-green-bg;
    --main-ltsfc-success-green-text: @main-ltsfc-success-green-text;
    --main-dksfc-success-green: @main-dksfc-success-green;
    --main-dksfc-success-green-bg: @main-dksfc-success-green-bg;
    --main-dksfc-success-green-text: @main-dksfc-success-green-text;

    --main-ltsfc-info-blue: @main-ltsfc-info-blue;
    --main-ltsfc-info-blue-bg: @main-ltsfc-info-blue-bg;
    --main-ltsfc-info-blue-text: @main-ltsfc-info-blue-text;
    --main-dksfc-info-blue: @main-dksfc-info-blue;
    --main-dksfc-info-blue-bg: @main-dksfc-info-blue-bg;
    --main-dksfc-info-blue-text: @main-dksfc-info-blue-text;
}

