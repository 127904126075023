.alert {
    top: 85px;
}

.alert * {
    animation: none !important;
    min-width: 16px;
}
.secondary-button,
.primary-button {
    min-width: 140px;
}
