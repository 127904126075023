.main.dark {
    background: var(--r-catapult-black);
    color: white;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 5% 0;
    position: relative;
    max-width: 90%;
    width: 640px;
}

.narrow {
    width: 360px;
}

.center {
    text-align: center;
}

.content {
    width: 100%;
}

body svg.logo {
    height: auto;
    margin-bottom: 12vh;
    width: auto;
}

.container a:not([class^='MuiButton']) {
    text-decoration: underline;
}
