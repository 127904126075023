.container {
    border: solid 1.5px var(--r-grey-5);
    border-radius: 3px;
}

.container p {
    margin: 0;
    display: inline;
}

.icon {
    align-items: center;
    border: solid 2px var(--r-grey-6);
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: 32px;
    width: 32px;
}

body .icon svg {
    height: 24px;
    width: 24px;
}

body .icon svg path {
    fill: var(--r-catapult-black);
}

body .read-icon {
    background: var(--r-catapult-orange);
    background-clip: padding-box;
    margin: 4px;
    border-radius: 50%;
    padding: 4px;
}
:root {
    --hover-color: rgba(16, 16, 16, 0.04);
    --active-color: rgba(16, 16, 16, 0.12);
}

body .read-icon.is-read {
    background-color: var(--r-catapult-white);
    border: solid 1px var(--r-grey-5);
    padding: 3px;
}

body .read-icon:hover,
body .read-icon:active {
    border: solid 4px;
    margin: 0;
}

body .read-icon:hover {
    border-color: var(--hover-color);
    margin: 0;
}

body .read-icon:active {
    border-color: var(--active-color);
    margin: 0;
}

body .read-icon.is-read:hover,
body .read-icon.is-read:active {
    padding: 4px;
}

body .read-icon.is-read:hover {
    background-color: var(--hover-color);
}

body .read-icon.is-read:active {
    background-color: var(--active-color);
}

body .read-icon:global.Mui-focusVisible {
    border: solid 4px;
    border-color: var(--active-color);
    margin: 0;
    outline: solid 4px var(--main-ltsfc-info-blue);
}

body .read-icon.is-read:global.Mui-focusVisible {
    background-color: var(--active-color);
    padding: 4px;
}

.profile-value-change {
    color: var(--main-ltsfc-success-green);
}
